import React from "react"
import ProgramsCalendar from "../components/Programs/Calendar"
import Container from "../components/Layout/Container"
import ProgramNav from "../components/Programs/Nav"

export default function ProgramGuide() {
  return (
    <Container page="Programmagids">
      <div className="program-guide container">
        <ProgramNav />
        <ProgramsCalendar />
      </div>
    </Container>
  )
}
