import { Link } from "gatsby"
import React, { useState } from "react"
import { imageUrl } from "../../helpers/ImageUrl"
import {
  formatProgramTime,
  getDuration,
  getRandomColor,
  nowPlayingSchedule,
} from "../../helpers/Programs"
import { getProgramCover } from "../../helpers/Publications"
import Icon from "../Icon/Icon"
import "./CalendarSlot.scss"

export default function CalendarSlot(props) {
  const { item, index, date } = props
  const [backVisible, setBackVisible] = useState(false)
  let isNow = false

  if (nowPlayingSchedule(item, date)) {
    isNow = true
  }

  function generateProgramUrl(slug) {
    return slug ? `/programmas/${slug}` : "/programmas"
  }

  if(!item.show) {
    return null;
  }

  return (
    <div
      className={`mhc__slot duration-${getDuration(item)} ${
        isNow ? " now" : ""
      }`}
      onMouseEnter={() => setBackVisible(true)}
      onMouseLeave={() => setBackVisible(false)}
    >
      <div className="mhc__slot--front">
        <h3 className="program__name">{item.show.name}</h3>
        <p className="program__time">
          <Icon name="clock" /> <span> {formatProgramTime(item)}</span>
        </p>
      </div>
      {backVisible && (
        <div
          className="mhc__slot--back"
          style={{ backgroundColor: getRandomColor(index) }}
        >
          <img
            src={imageUrl(getProgramCover(item.show.cover), 185, 85)}
            alt={item.show.name}
          />
          <div className="mhc__slot--inner">
            <h3 className="program__name">{item.show.name}</h3>
            <hr />
            <p className="program__time">
              <Icon name="clock" /> <span> {formatProgramTime(item)}</span>
            </p>
            <Link to={generateProgramUrl(item.show.slug)}>Lees meer...</Link>
          </div>
        </div>
      )}
    </div>
  )
}
