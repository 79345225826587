import React, { useEffect, useState, Fragment } from "react"
import "./Calendar.scss"
import CalendarSlot from "./CalendarSlot"
import { useGuestApi } from "../../hooks/useApi"
import { DateTime } from "luxon"
import {
  convertDayOfWeekToReadable,
  checkForCurrentWeek,
  isToday,
} from "../../helpers/Schedule"
import Icon from "../Icon/Icon"

function ProgramsCalendar() {
  const hours = [
    "0u",
    "1u",
    "2u",
    "3u",
    "4u",
    "5u",
    "6u",
    "7u",
    "8u",
    "9u",
    "10u",
    "11u",
    "12u",
    "13u",
    "14u",
    "15u",
    "16u",
    "17u",
    "18u",
    "19u",
    "20u",
    "21u",
    "22u",
    "23u",
    "24u",
  ]
  const { getProgramGuideRange } = useGuestApi()
  const [guide, setGuide] = useState([])
  const [range, setRange] = useState({
    from: DateTime.local().startOf("week").toISODate(),
    until: DateTime.local().endOf("week").toISODate(),
  })

  useEffect(() => {
    getProgramGuideRange(range.from, range.until).then(response => {
      setGuide(response)
    })
  }, [getProgramGuideRange, range])

  function nextWeek() {
    const fromNextWeek = DateTime.fromISO(range.from)
      .plus({ days: 7 })
      .toISODate()
    const untilNextWeek = DateTime.fromISO(range.until)
      .plus({ days: 7 })
      .toISODate()
    setRange({ from: fromNextWeek, until: untilNextWeek })
  }

  function previousWeek() {
    if (!checkForCurrentWeek(range)) {
      const fromNextWeek = DateTime.fromISO(range.from)
        .minus({ days: 7 })
        .toISODate()
      const untilNextWeek = DateTime.fromISO(range.until)
        .minus({ days: 7 })
        .toISODate()
      setRange({ from: fromNextWeek, until: untilNextWeek })
    }
  }

  return (
    <div className="mhc-container">
      <div className="mhc__arrows">
        <button
          className={`mhc__arrow ${
            checkForCurrentWeek(range) ? "current" : ""
          }`}
          onClick={() => previousWeek()}
          disabled={checkForCurrentWeek(range)}
        >
          Deze week
        </button>
        <button
          className={`mhc__arrow ${
            checkForCurrentWeek(range) ? "" : "current"
          }`}
          onClick={() => nextWeek()}
          disabled={!checkForCurrentWeek(range)}
        >
          Volgende week
        </button>
      </div>
      <div className="mhc-scroller">
        <div className="mhc">
          <div className="mhc__hours">
            <div className="mhc__icon">
              <Icon name="clock" />
            </div>
            {hours.map((hour, i) => (
              <div className="mhc__hour" key={i}>
                <span>{hour}</span>
              </div>
            ))}
          </div>
          {guide.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  className={`mhc__weekday ${
                    isToday(item.date) ? "today" : null
                  }`}
                >
                  <h3>
                    {convertDayOfWeekToReadable(
                      DateTime.fromISO(item.date).weekday
                    )}
                  </h3>
                  <h4>
                    {DateTime.fromISO(item.date).day}/
                    {DateTime.fromISO(item.date).month}
                  </h4>
                </div>
                <div
                  className={`mhc__day ${isToday(item.date) ? "today" : null}`}
                >
                  {item.slots.map((program, i) => (
                    <CalendarSlot
                      date={item.date}
                      item={program}
                      index={i}
                      key={i}
                    />
                  ))}
                </div>
              </Fragment>
            )
          })}
          <div className="mhc__footer" />
        </div>
      </div>
    </div>
  )
}

export default ProgramsCalendar
