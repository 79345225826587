import { DateTime } from "luxon"
import { defaultColors } from "../constants/colors"

export function nowPlayingSchedule(data, date) {
  const currentDate = DateTime.local().toISODate()
  if (date === currentDate) {
    let from = DateTime.fromFormat(data.from, "HH:mm:ss")
    let until = DateTime.fromFormat(data.until, "HH:mm:ss")
    return DateTime.local().toUTC() > from && DateTime.local().toUTC() < until
  }
  return false
}

export function nowPlaying(data) {
  let from = DateTime.fromFormat(data.from, "HH:mm:ss")
  let until = DateTime.fromFormat(data.until, "HH:mm:ss")
  return DateTime.local().toUTC() > from && DateTime.local().toUTC() < until
}

export function formatProgramTime(data) {
  let from = DateTime.fromFormat(data.from, "HH:mm:ss")
  let until = DateTime.fromFormat(data.until, "HH:mm:ss")
  return `${from.toLocaleString(
    DateTime.TIME_24_SIMPLE
  )} - ${until.toLocaleString(DateTime.TIME_24_SIMPLE)}`
}

export function getDuration(data) {
  let from = DateTime.fromFormat(data.from, "HH:mm:ss")
  let until = DateTime.fromFormat(data.until, "HH:mm:ss")
  return Math.round((until - from) / 3600000)
}

export function getRandomColor(index) {
  if (0 % index === 0) {
    return defaultColors[index] ?? defaultColors[0]
  } else if (1 % index === 0) {
    return defaultColors[index] ?? defaultColors[1]
  } else if (2 % index === 0) {
    return defaultColors[index] ?? defaultColors[2]
  } else if (3 % index === 0) {
    return defaultColors[index] ?? defaultColors[3]
  }
  return defaultColors[0]
}
