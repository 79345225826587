import { DateTime } from "luxon"
export function convertDayOfWeekToReadable(dayOfWeek) {
  if (dayOfWeek === 1) {
    return "Maandag"
  } else if (dayOfWeek === 2) {
    return "Dinsdag"
  } else if (dayOfWeek === 3) {
    return "Woensdag"
  } else if (dayOfWeek === 4) {
    return "Donderdag"
  } else if (dayOfWeek === 5) {
    return "Vrijdag"
  } else if (dayOfWeek === 6) {
    return "Zaterdag"
  } else if (dayOfWeek === 7) {
    return "Zondag"
  }
}

export function checkForCurrentWeek(range) {
  const currentWeek = DateTime.local().weekNumber
  if (currentWeek === DateTime.fromISO(range.from).weekNumber) {
    return true
  }
  return false
}

export function isToday(day) {
  if (
    DateTime.fromISO(day).toISODate() ===
    DateTime.fromJSDate(new Date()).toISODate()
  ) {
    return true
  }
  return false
}
